<template>
  <div>
    <In-Head :title="$t('customers.transactions.show.title', {name: $page.props.customer.name})" />

    <sub-header
      :title="$t('customers.transactions.show.title', {name: $page.props.customer.name})"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
        <withdraw-modal />
      </template>
    </sub-header>

    <customers-nav-tabs />

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.transactions.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'paid' || col === 'ntak_success'">
                <sm-boolean-flag :value="slotProps.data?.[col]" />
              </template>
              <template v-else-if="col === 'payer'">
                <sm-avatar
                  :user="{
                    name: slotProps.data?.payer.name,
                    avatar: slotProps.data?.payer.picture,
                    profile_url: slotProps.data?.payer.routes?.show
                  }" />
              </template>
              <template v-else-if="col === 'amount'">
                <template v-if="slotProps.data?.is_deposit">
                  <span class="font-medium text-bo-green">{{ slotProps?.data.amount }}</span>
                </template>
                <template v-else>
                  <span
                    v-if="slotProps.data?.payment_type === 'balance'"
                    class="font-medium text-bo-red">-{{ slotProps.data?.amount }}</span>
                  <span
                    v-else
                    class="font-medium text-bo-gray-800">-{{ slotProps.data?.amount }}</span>
                </template>
                <a
                  v-if="slotProps.data?.permissions.showRefundedTransaction"
                  v-tippy="$t('customers.transactions.refunded_transaction')"
                  :href="slotProps.data?.routes.refundedTransaction"
                  class="cursor-pointer">
                  <vue-feather
                    type="repeat"
                    size="14"
                    class="ml-1 text-bo-blue" />
                </a>
              </template>
              <template v-else-if="col === 'status'">
                {{ $t(`customers.transactions.payment_status.${slotProps.data?.status}`) }}
              </template>
              <template v-else-if="col === 'payment_type'">
                {{ slotProps.data?.payment_type !== '-' ? $t(`payment_types.${slotProps.data?.payment_type}`) : '-' }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.show"
                  size="icon"
                  icon="info"
                  old-link
                  :href="slotProps.data?.routes.show">
                  <vue-feather
                    class="inline"
                    type="info" />
                </sm-button>
                <sm-button
                  v-if="slotProps.data?.permissions.update"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.update" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";
import WithdrawModal from "./_modals/WithdrawModal.vue";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["paid", "id", "ntak_success", "payer", "amount", "status", "payment_type", "locked_at", "paid_at", "invoice"]);
</script>
